.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 0.8fr;
  grid-template-rows: repeat(4, 1fr);
  background-color: #1d649d;
  padding: 10px;
  width: 100vw;
  height: 100vh;
}
.other-view1 {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}
.other-view2 {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}
.other-view3 {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 3;
  grid-column-end: 4;
}
.room-layout {
  grid-row-start: 3;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 2;
}
.self-forward {
  grid-row-start: 3;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 3;
}
.self-cam {
  grid-row-start: 3;
  grid-row-end: 5;
  grid-column-start: 3;
  grid-column-end: 4;
}
.room-info {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 4;
  grid-column-end: 5;
}
.room-chat {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 4;
  grid-column-end: 5;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  height: auto;
  width: auto;
  font-size: 30px;
  text-align: center;
}
.grid-item p {
  font-size: 15px;
}
#floor-plan-image {
  width: 20vw;
}
